import { BlockOutlined, CopyOutlined, DeleteOutlined, EditOutlined, PlaySquareOutlined, PlusOutlined, CheckCircleOutlined, EyeOutlined, PauseCircleOutlined, ReloadOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, Input, List, Row, Space, Tooltip, Typography, message, Popconfirm, Popover, Empty, ConfigProvider, Collapse, Tabs } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';
import { DateTime } from 'luxon';

import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { editProject, editProjectSnippet, getProject, getProjectSnippets, getPrompts, getWorkflows, deleteProjectSnippet, deleteWorkflow, editWorkflow, getWorkflowExecutions } from '../../services/signalApi';
import { ProjectSnippet as ProjectSnippetType, Project as ProjectType, Prompt as PromptType, Workflow as WorkflowType, WorkflowExecution as WorkflowExecutionType } from '../../types/types';

import { useAppDispatch, useAppSelector, useIsBigScreen } from '../../app/hooks';
import { CopyToClipboard } from '../../components/CopyToClipboard';
import { IconText } from '../../components/IconText';
import { Loading } from '../../components/Loading';
import { NewSnippetForm } from '../../components/NewSnippetForm';
import { WorkflowExecutionsListUi } from '../../components/WorkflowExecutionsList';
import { CreateWorkflowForm } from '../../components/CreateWorkflowForm'
import { ConfiguredWorkflowRunner } from '../../components/ConfiguredWorkflowRunner';
import { WorkflowConfigurationForm } from '../../components/WorkflowConfigurationForm';
import { ToggleToMarkdown } from '../../components/ToggleToMarkdown';
import { CreateWorkflowFromPromptForm } from '../../components/CreateWorkflowFromPromptForm';
import { BetaTag } from '../../components/BetaTag';
import { useQuery } from '@tanstack/react-query'
import { COMMON_EMPTY_RESPONSE, isBetaUser } from '../../utils'
// import { MarkdownContent } from '../../components/MarkdownContent';

import { default as BankNotesIcon } from '../../images/banknotes'



import ReactGA from "react-ga4";



import {
    selectDataSets
} from '../../features/dataSetList/dataSetSlice';
import { selectAccount } from '../../features/login/loginSlice';
// import { ProjectSubscribeModal } from '../../components/ProjectSubscribeModal'
// import { ProjectEditModal } from '../../components/ProjectEditModal'
import 'draft-js/dist/Draft.css';
import { WorkflowPreview } from '../../components/WorkflowPreview';
const { TextArea } = Input;
const enabledColor = '#8bbb11'
const disabledColor = '#df7e2d'

const snippetsEmpty = () => (
    <Empty description={"No Snippets"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
)

const emptyLoading = () => (
    <Empty description={"Loading..."} image={Empty.PRESENTED_IMAGE_SIMPLE} />
)

const workflowsEmpty = () => (
    <Empty description={"No Workflows"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
)

const promptsEmpty = () => (
    <Empty description={"No Prompts"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
)

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function EditableWorkflowName({
    workflow,
    onUpdate,
}: {
    workflow: WorkflowType,
    onUpdate: (project: WorkflowType) => void
}) {
    const [editMode, setEditMode] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [form] = Form.useForm();

    const onFinish = ({name}: {name: string}) => {
        console.log("name updated", {name})

        setIsEditing(true);
        editWorkflow(
            workflow.id,
            { name }
        ).then((res) => {
            if (res.status === 200) {
                const response_data = res.data;
                setIsEditing(false);
                onUpdate(response_data);
                setEditMode(false)
            } else {
                message.error(res.data);
                setIsEditing(false);
            }
        }).catch((err) => {
            console.log("err", {err});
        }).finally(() => {
            setIsEditing(false);
        });
    }

    if (editMode) {
        return <Form form={form} initialValues={{name: workflow.name}} layout="inline" onFinish={onFinish}>
            <Form.Item
                name="name"
                rules={[{ required: true, message: 'Workflows need a name' }]}
            >
                <Input placeholder="Workflow Name" />
            </Form.Item>
            <Form.Item shouldUpdate={(prevValues, curValues) =>
                prevValues.name !== curValues.name
            }>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                            // !clientReady ||
                            !!isEditing ||
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                    >
                    Save
                    </Button>
                )}
            </Form.Item>

            <Button
                type="default"
                // htmlType="submit"
                onClick={() => setEditMode(false)}
            >
                    Cancel
            </Button>

        </Form>
    }

    return <>
        Workflow: {workflow.name} <Tooltip placement="top" title="Edit Workflow Name">
            <Button onClick={() => {setEditMode(!editMode)}} icon={<EditOutlined />} type='text' />
        </Tooltip>
    </>
}

export function UpdateProjectForm({
    project,
    onCancel,
    onUpdate,
}: {
    project: ProjectType
    onCancel: () => void
    onUpdate: (project: ProjectType) => void
}) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const [isEditing, setIsEditing] = useState(false);

    const handleCancel = () => {
        form.resetFields();
        setIsEditing(false);
        onCancel();
    }

    const onCreateProjectFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateProjectSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateProject(values);
    };

    const onCreateProject = (values: any) => {
        if (account) {
            setIsEditing(true);
            editProject(
                project.id,
                values
            ).then((res) => {
                if (res.status === 200) {
                    const response_data = res.data;
                    setIsEditing(false);
                    onUpdate(response_data);
                } else {
                    message.error(res.data);
                    setIsEditing(false);
                }  
            }).catch((err) => {
                console.log("err", {err});
            }).finally(() => {
                setIsEditing(false);
            });
        }
    }

    return <Form
        name="project"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
            name: project.name,
            description: project.description
        }}
        onFinish={onCreateProjectSubmit}
        onFinishFailed={onCreateProjectFailed}
        autoComplete="off"
    >
        {(isEditing && (
            <Typography.Paragraph>Loading...</Typography.Paragraph>
        ))}
        
        <>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Name your Project' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Description"
                name="description"
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                wrapperCol={{ span: 16, offset: 6 }}
            >
                <Space>
                    <Button type="primary" htmlType="submit" disabled={isEditing}>
                        Save
                    </Button>
                    <Button type="default" onClick={onCancel}>
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </>

    </Form>
}

interface ProjectResourceListParams {
    project_id: string
    onSelectResource: (resource: ProjectSnippetType | WorkflowType) => void
}

export function ProjectResourceList({
    project_id,
    onSelectResource,
}: ProjectResourceListParams) {

    const data = [
        'Racing car sprays burning fuel into crowd.',
        'Japanese princess to wed commoner.',
        'Australian walks 100km after outback crash.',
        'Man charged over missing wedding girl.',
        'Los Angeles battles huge wildfires.',
    ];

    return <>
        <List
            size="large"
            header={<div>Snippets</div>}
            // footer={<div>Footer</div>}
            // bordered
            dataSource={data}
            renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        <List
            size="large"
            header={<div>Workflows</div>}
            // footer={<div>Footer</div>}
            // bordered
            dataSource={data}
            renderItem={(item) => <List.Item>{item}</List.Item>}
        />
    </>

}


export function WorkflowDetail({workflow}: {workflow: WorkflowType}){
    if (workflow.config.triggers.length === 0 || (workflow.config.step_definition?.args.block_steps || []).length === 0) {
        return <span>-</span>

    }
    const trigger_strs = workflow.config.triggers.map((trigger) => {
        if (trigger.trigger_type === 'form_submit') {
            return `Form${(trigger.args.submission_params || []).length > 0 ? ':' : ''} ${trigger.args.submission_params.map((p) => p.field).join(', ')}`
        } else if (trigger.trigger_type === 'timed') {
            return "Timed"
        }
        else {
            return "None"
        }
    }).join(", ")

    const steps_str = (workflow.config.step_definition?.args.block_steps || []).map((step) => {
        if (step.block_step_type === 'execute_prompt') {
            // return `Prompt: ${action.args.message}`
            return `Prompt`
        }
        if (step.block_step_type === 'send_email') {
            return `Send Email`
        }
        if (step.block_step_type === 'set_project_snippet') {
            return `Set Snippet '${step.args.snippet_name}'`
        }
        if (step.block_step_type === 'set_result') {
            return `Set Result`
        }
        if (step.block_step_type === 'run_workflows') {
            return `Run Workflows`
        }
        return 'Step'
    }).join(", ")

    return <span>{trigger_strs} &#8594; {steps_str}</span>
}

export function ManageSnippet({
    snippet,
    onCancel,
    onUpdate,
}: {
    snippet: ProjectSnippetType
    onCancel: () => void
    onUpdate: () => void
}) {
    const [form] = Form.useForm();

    const account = useAppSelector(selectAccount);
    const [isSaving, setIsSaving] = useState(false);
    // const [hasChanged, setHasChanged] = useState(false);
    const newSnippetName = Form.useWatch('name', form);
    const newSnippetContent = Form.useWatch('content', form);

    const hasChanged = snippet.name != newSnippetName|| snippet.content !== newSnippetContent

    const handleCancel = () => {
        form.resetFields();
        setIsSaving(false);
        onCancel();
    }

    const onCreateProjectSnippetFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCreateProjectSnippetSubmit = (values: any) => {
        console.log('Success:', {values, account});
        onCreateProjectSnippet(values);
    };

    const onCancelClick = () => {
        form.resetFields()
        onCancel()
    }

    const onCreateProjectSnippet = (values: any) => {
        if (account) {
            setIsSaving(true);
            editProjectSnippet(
                snippet.id,
                values
            ).then((res) => {
                if (res.status === 200) {
                    const response_data = res.data;
                    setIsSaving(false);
                } else {
                    message.error(res.data);
                    setIsSaving(false);
                }  
            }).catch((err) => {
                console.log("err", {err});
            }).finally(() => {
                setIsSaving(false);
                onUpdate();
            });
        }
    }

    return <Form
        name="snippet"
        form={form}
        layout='vertical'
        initialValues={{
            name: snippet.name,
            content: snippet.content
        }}
        onFinish={onCreateProjectSnippetSubmit}
        onFinishFailed={onCreateProjectSnippetFailed}
        autoComplete="off"
        requiredMark={"optional"}
    >
        {(isSaving && (
            <Typography.Paragraph>Loading...</Typography.Paragraph>
        ))}
        
        <>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Name your Snippet' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Content"
                name="content"
            >
                <Input.TextArea showCount style={{height: 360}} />
            </Form.Item>

            {hasChanged && (
                <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                    <Space style={{float: 'right', width: 'unset'}}>
                        <Button type="primary" htmlType="submit" disabled={isSaving}>
                            Save
                        </Button>
                        <Button type="default" onClick={onCancelClick}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>
            )}


        </>

    </Form>
}




export function Project() {
    const params = useParams()
    const account = useAppSelector(selectAccount)
    
    const [editMode, setEditMode] = useState(false);
    

    const [searchParams, setSearchParams] = useSearchParams();

    // const [selectedResource, setSelectedResource] = useState<WorkflowType | ProjectSnippetType | PromptType | null>(null);
    const selectedResourceId = searchParams.get("v")

    // const [dataSets, setDataSets] = useState<DataSet[] | null>(null);
    const isBigScreen = useIsBigScreen()


    const id = params.id;


    const {
        data: { data: project} = { data: null},
        refetch: refetchProject,
        isFetching: isFetchingProject,
        isFetched: isFetchedProject,
        isPending: isPendingProject
    } = useQuery({
        queryKey: ['project', id],
        staleTime: 1000 * 60 * 15,
        enabled: !!id,
        queryFn: () => {
            if (!id) {
                return Promise.reject(new Error('No ID'))
            }
            return getProject(id)
            // return sleep(2000).then(() => getProject(id))
        }
    })

    const {
        data: { data: { count: loadedProjectSnippetsCount, results: loadedProjectSnippets}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchProjectSnippets,
        isFetched: isFetchedProjectSnippets,
        isFetching: isFetchingProjectSnippets,
    } = useQuery({
        queryKey: ['project', id, 'snippets'],
        staleTime: 1000 * 60 * 15,
        enabled: !!id && !!project && !isFetchingProject && !isPendingProject,
        queryFn: () => {
            // return sleep(2000).then(() => getProjectSnippets({
            //     project_id: id,
            //     ordering: 'name'
            // }))

            return getProjectSnippets({
                project_id: id,
                ordering: 'name'
            })
        }
    })

    const {
        data: { data: { count: loadedWorkflowsCount, results: loadedWorkflows}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchWorkflows,
        isFetched: isFetchedWorkflows,
        isFetching: isFetchingWorkflows,
    } = useQuery({
        queryKey: ['project', id, 'workflows'],
        staleTime: 1000 * 60 * 15,
        enabled: !!id && !!project && !isFetchingProjectSnippets,
        queryFn: () => {
            return getWorkflows({
                project_id: id,
                ordering: 'name'
            })
        }
    })

    const prompt_ids = (loadedWorkflows || []).map((workflow) =>
        (workflow.config.step_definition?.args.block_steps || [])
            .map((s => s.block_step_type === 'execute_prompt' ? s.args.prompt_id : null))
            .filter((s => s !== null))
    ).reduce((agg, workflow_prompt_ids) => {
        workflow_prompt_ids.forEach((prompt_id) => {
            if (agg.indexOf(prompt_id) === -1) {
                agg.push(prompt_id)
            }
        })
        return agg
    }, [])

    const {
        data: { data: { count: loadedPromptsCount, results: loadedPrompts}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchPrompts,
        isFetched: isFetchedPrompts,
        isFetching: isFetchingPrompts,
    } = useQuery({
        queryKey: ['project', id, 'prompts', prompt_ids.join(',')],
        staleTime: 1000 * 60 * 15,
        enabled: !!id && !!project && !isFetchingProjectSnippets && !isFetchingWorkflows,
        queryFn: () => {
            // const prompt_ids_needed = prompt_ids.filter((id) => id && !workflowPromptIds.includes(id))
            if (prompt_ids.length > 0) {
                return getPrompts({id__in: prompt_ids.join(',')})
            }
            return
        }
    })

    const {
        data: { data: { count: loadedWorkflowExecutionsCount, results: loadedWorkflowExecutions}} = COMMON_EMPTY_RESPONSE,
        refetch: refetchWorkflowExecutions,
        isFetched: isFetchedWorkflowExecutions,
        isFetching: isFetchingWorkflowExecutions,
    } = useQuery({
        queryKey: ['project', id, 'workflow_executions'],
        staleTime: 1000 * 60 * 15,
        enabled: !!id && !!project && !isFetchingProjectSnippets && !isFetchingWorkflows && !isFetchingPrompts,
        queryFn: () => {
            return getWorkflowExecutions({
                workflow__project_id: id,
                ordering: '-created_date'
            })
        }
    })



    const refetchAll = () => {
        refetchProject()
            .then(() => refetchProjectSnippets())
            .then(() => refetchWorkflows())
            .then(() => refetchPrompts())
            .then(() => refetchWorkflowExecutions())
    }

    
    

    useEffect(() => {
        if (project === null) {
            document.title = 'PyroPrompts | Project'

        } else {
            document.title = `PyroPrompts | Project: ${project.name}`
        }
    }, [project?.id])


    const selectedResource: WorkflowType | ProjectSnippetType | PromptType | null = [...(loadedProjectSnippets || []), ...(loadedWorkflows || []), ...(loadedPrompts || []) ].filter((r) => r.id === selectedResourceId)[0] || null


    const onDeleteProjectSnippet = (snippetId: string) => {
        if (account) {
            deleteProjectSnippet(
                snippetId
            ).then((res) => {
                if (res.status === 204) {
                    const response_data = res.data;
                    refetchProjectSnippets()
                    // setReloads(reloads + 1)
                } else {
                    message.error(res.data);
                }  
            })
        }
    }

    const onDeleteWorkflow = (workflowId: string) => {
        if (account) {
            deleteWorkflow(
                workflowId
            ).then((res) => {
                if (res.status === 204) {
                    const response_data = res.data;
                    refetchWorkflows()
                    // setReloads(reloads + 1)
                } else {
                    message.error(res.data);
                }  
            })
        }
    }

    const onEnableWorkflow = (workflowId: string) => {
        editWorkflow(workflowId, {enabled: 1}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Enabled!")
                refetchWorkflows()
                // setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error(`Failure: ${res.data}`)
            }
        })
    };

    const onDisableWorkflow = (workflowId: string) => {
        editWorkflow(workflowId, {enabled: 0}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Disabled!")
                refetchWorkflows()
                // setReloads(reloads + 1)
            } else {
                console.log(res.data)
                message.error("Failure!")
            }
        })
    };



    if (!id) {
        return <Typography.Paragraph>No ID</Typography.Paragraph>
    }

    if (id === undefined || project === undefined || project === null) {
        return (
            <div className="App">
                <div className="App-content">
                    {!isFetchingProject && (
                        <Typography.Paragraph>Project Not Found. Maybe you need to log in.</Typography.Paragraph>
                    )}
                    {isFetchingProject && (
                        <Loading message='Project' description='Loading Project' style={{marginTop: '12px'}} />

                    )}
                </div>
            </div>
        );
    }

    const snippetPopoverContent = (projectSnippet: ProjectSnippetType) => (
        <div style={{width: "500px"}} onClick={e => e.stopPropagation()}>
            {/* <TradingViewChartWidget symbol={symbol} /> */}
            <>
                <Typography.Paragraph>Snippet: {projectSnippet.name}</Typography.Paragraph>
                <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 5px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{projectSnippet.content || ''}</Typography.Paragraph>
                {/* <Typography.Paragraph style={{fontSize: '0.8rem', margin: "0"}}>click to copy</Typography.Paragraph> */}
            </>
        </div>
    );

    const resourceListStyle = {
        cursor: 'pointer',
        padding: '8px',
        overflow: 'hidden',
    }


    const content = (
        <>

            <Row gutter={8} style={{marginTop: '12px'}}>
                <Col xs={24} md={8}>
                    <Card
                        title={'Project'}
                        style={{marginBottom: '12px'}}
                        extra={
                            <Space>
                                <Tooltip placement="top" title="Reload">
                                    <Button onClick={() => {refetchAll()}} icon={<ReloadOutlined />} type='text' />
                                </Tooltip>
                                <Tooltip placement="top" title="Edit Project">
                                    <Button onClick={() => {setEditMode(!editMode)}} icon={<EditOutlined />} type='text' />
                                </Tooltip>
                            </Space>
                        }

                    >
                        <Space direction='vertical' style={{width: '100%'}}>
                            {!editMode && (<>
                                <Typography.Title level={3} style={{marginTop: 0}}><BlockOutlined />&nbsp;{project.name}</Typography.Title>
                                <Typography.Paragraph>{project.description}</Typography.Paragraph>
                                <Space style={{fontSize:'0.85em' }}><span>Private to just you</span><Divider type='vertical'/><span>Created {DateTime.fromISO(project.created_date).toRelative()}</span></Space>
                            </>)}
                            {editMode && (<>
                                <UpdateProjectForm project={project} onCancel={() => setEditMode(false)} onUpdate={(project) => {
                                    setEditMode(false)
                                    refetchProject()
                                    // setProject(project)
                                    // triggerReload()
                                }} />
                            </>)}
                            {/* <ProjectDiagram project={project} workflows={[]} snippets={[]} /> */}
                            {/* <ProjectSnippetsList project_id={project.id} /> */}
                            {/* <WorkflowList project_id={id} showHead={true}/> */}
                            {/* <ProjectResourceList project_id={id} onSelectResource={(resource: ProjectSnippetType | WorkflowType) => {console.log(resource)}}/> */}
                            <>
                                {/* <List
                                // size="large"
                                header={<div><Typography.Text strong>Activity</Typography.Text></div>}
                                dataSource={["Executions", "Chat"]}
                                renderItem={(item) => <List.Item
                                    onClick={() => {
                                        if (selectedResourceId === item.toLowerCase()) {
                                            searchParams.delete("v")
                                            setSearchParams(searchParams)
                                        } else {
                                            searchParams.set("v", item.toLowerCase())
                                            setSearchParams(searchParams)
                                        }
                                    }}
                                    style={{...resourceListStyle, ...(selectedResourceId === item.toLowerCase() ? {background: 'rgba(120, 120, 120, 0.15)'} : {})}}
                                    >
                                        {item}
                                    </List.Item>
                                }
                            /> */}

                                <ConfigProvider
                                    renderEmpty={isFetchedProjectSnippets ? snippetsEmpty : emptyLoading}
                                >
                                    <List
                                        // size="large"
                                        header={<Space style={{justifyContent: "space-between", display: "flex"}}>
                                            <Typography.Text strong>Snippets ({loadedProjectSnippetsCount})</Typography.Text>
                                            <Space>
                                                <Tooltip placement="top" title="Add Snippet">

                                                    <Button onClick={() => {
                                                        searchParams.set("v", 'new-snippet')
                                                        setSearchParams(searchParams)
                                                    }} icon={<PlusOutlined />} type='text' />
                                                </Tooltip>
                                                {/* <Button onClick={() => {}} block icon={<DeleteOutlined />}>
                                    Clear All
                                    </Button> */}
                                            </Space>
                                        </Space>}
                                        // footer={<div>Footer</div>}
                                        // bordered
                                        dataSource={loadedProjectSnippets || []}
                                        renderItem={(item) => <List.Item
                                            onClick={() => {
                                                if (selectedResource && selectedResource.id === item.id) {
                                                    searchParams.delete("v")
                                                    setSearchParams(searchParams)
                                                } else {
                                                    searchParams.set("v", item.id)
                                                    setSearchParams(searchParams)
                                                }
                                            }}
                                            actions={[
                                                ...(item.content && item.content.length ? [
                                                    ...(isBigScreen ? [(
                                                        <Popover key={item.id} content={snippetPopoverContent(item)} mouseEnterDelay={0.2} placement='right'>
                                                            <EyeOutlined />
                                                        </Popover>)] : []),
                                                    <span onClick={e => e.stopPropagation()}><CopyToClipboard text={item.content || ''} tooltip={'Copy to Clipboard'} content={<CopyOutlined /> } /></span>,

                                                ] : [])
                                            ]}
                                            style={{...resourceListStyle, ...(selectedResource && item.id === selectedResource.id ? {background: 'rgba(120, 120, 120, 0.15)'} : {})}}>
                                            {item.content && item.content.length > 0 ? <CheckCircleOutlined style={{ color: enabledColor }} /> : <CloseCircleOutlined style={{ color: disabledColor }} />}&nbsp;&nbsp;{item.name}
                                        </List.Item>
                                        }
                                    />
                                </ConfigProvider>
                                <ConfigProvider
                                    renderEmpty={isFetchedWorkflows ? workflowsEmpty : emptyLoading}
                                >
                                    <List
                                        // size="large"
                                        header={
                                            <Space style={{justifyContent: "space-between", display: "flex"}}>
                                                <Typography.Text strong>Workflows ({loadedWorkflowsCount})</Typography.Text>
                                                <Space>
                                                    <Tooltip placement="top" title="Add Workflow">

                                                        <Button onClick={() => {
                                                            searchParams.set("v", 'new-workflow')
                                                            setSearchParams(searchParams)
                                                        }} icon={<PlusOutlined />} type='text' />
                                                    </Tooltip>

                                                    {/* <Button onClick={() => {}} block icon={<PlaySquareOutlined />}>
                                            Enable All
                                            </Button> */}
                                                </Space>
                                            </Space>
                                        }
                                        // footer={<div>Footer</div>}
                                        // bordered
                                        dataSource={loadedWorkflows || []}
                                        renderItem={(item) => <List.Item
                                            onClick={() => {
                                                if (selectedResource && selectedResource.id === item.id) {
                                                    searchParams.delete("v")
                                                    setSearchParams(searchParams)
                                                } else {
                                                    searchParams.set("v", item.id)
                                                    setSearchParams(searchParams)
                                                }
                                            }}
                                            actions={[
                                                ...(item.config ? [
                                                    <Popover
                                                        key={item.id}
                                                        content={
                                                            <div>
                                                                <Typography.Title level={4} style={{marginTop: 0}}>Workflow</Typography.Title>
                                                                <WorkflowPreview workflow={item} prompts={loadedPrompts || []} />
                                                            </div>
                                                        } mouseEnterDelay={0.2} placement='right'>
                                                        <EyeOutlined />
                                                    </Popover>]: []),
                                                ...(item.credit_price ? [<><Tooltip placement="top" title={`Workflow costs ${item.credit_price} credits to run.`}><BankNotesIcon style={{fontSize: "1.4em"}} height={"0.7em"} />&nbsp;{item.credit_price}</Tooltip></>] : [])
                                            ]}
                                            style={{...resourceListStyle, ...(selectedResource && item.id === selectedResource.id ? {background: 'rgba(120, 120, 120, 0.15)'} : {})}}>
                                            {item.enabled ? <CheckCircleOutlined style={{ color: enabledColor }} /> : <PauseCircleOutlined style={{ color: disabledColor }} />}&nbsp;&nbsp;<span>{item.name}</span>
                                        </List.Item>
                                        }
                                    />
                                </ConfigProvider>
                                <ConfigProvider
                                    renderEmpty={isFetchedPrompts ? promptsEmpty : emptyLoading}
                                >
                                    <List
                                        // size="large"
                                        header={<div><Typography.Text strong>Prompts ({loadedPromptsCount})</Typography.Text></div>}
                                        // footer={<div>Footer</div>}
                                        // bordered
                                        dataSource={loadedPrompts || []}
                                        renderItem={(item) => <List.Item
                                            onClick={() => {
                                                if (selectedResource && selectedResource.id === item.id) {
                                                    searchParams.delete("v")
                                                    setSearchParams(searchParams)
                                                } else {
                                                    searchParams.set("v", item.id)
                                                    setSearchParams(searchParams)
                                                }
                                            }}
                                            style={{...resourceListStyle, ...(selectedResource && item.id === selectedResource.id ? {background: 'rgba(120, 120, 120, 0.15)'} : {})}}>
                                            {item.name}
                                        </List.Item>
                                        }
                                    />
                                </ConfigProvider>
                            </>
                        </Space>
                    </Card>

                </Col>
                <Col xs={24} md={16}>
                    {selectedResourceId === null && (
                        <Card
                            title={<>Project: {project.name}</>}
                        >
                            <Typography.Title level={3} style={{marginTop: 0}}>Describe a Workflow to Create</Typography.Title>
                            {isBetaUser(account?.name) && (
                                <CreateWorkflowFromPromptForm project={project} onCreate={() => refetchAll()} />
                            )}
                            <Typography.Title level={3} style={{marginTop: 0}}>Snippets</Typography.Title>
                            {/* <Typography.Paragraph>Show {project.name} Activity Here - TODO</Typography.Paragraph> */}
                            {/* <WorkflowExecutionsList project_id={project.id} key={project.id} show_workflow_link={true} /> */}
                            {loadedProjectSnippets?.length === 0 && (
                                <Typography.Paragraph>No Snippets</Typography.Paragraph>
                            )}
                            {(loadedProjectSnippets || []).map((snippet) => (
                                <div key={snippet.name}>
                                    <Typography.Title level={4}>{snippet.name}</Typography.Title>
                                    <ToggleToMarkdown markdown={snippet.content || '-'} />

                                </div>
                            ))}
                            <Typography.Title level={3} style={{marginTop: 0}}>Workflows</Typography.Title>
                            {loadedWorkflows?.length === 0 && (
                                <Typography.Paragraph>No Workflows</Typography.Paragraph>
                            )}
                            {(loadedWorkflows || []).map((workflow) => (
                                <div key={workflow.id}>
                                    <Typography.Title level={4}>{workflow.name}</Typography.Title>
                                    <WorkflowPreview workflow={workflow} prompts={loadedPrompts || []} />

                                </div>
                            ))}
                            {(loadedWorkflowExecutions || []).length > 0 && (
                                <>
                                    <Typography.Title level={3}>Executions</Typography.Title>
                                    <WorkflowExecutionsListUi workflowExecutions={(loadedWorkflowExecutions || [])} show_workflow_link={true} />
                                </>
                            )}
                        </Card>
                    )}
                    {selectedResourceId === 'executions' && (
                        <Card>
                            <Typography.Title level={2} style={{marginTop: 0}}>Project: {project.name} </Typography.Title>
                            <Typography.Paragraph>Show {project.name} Executions Here - TODO</Typography.Paragraph>
                            {/* <WorkflowExecutionsListUi project_id={project.id} key={project.id} show_workflow_link={true} /> */}
                        </Card>
                    )}
                    {selectedResourceId === 'new-snippet' && (
                        <Card
                            title={<>New Snippet</>}
                        >
                            {/* <Typography.Title level={2} style={{marginTop: 0}}>Snippet: {selectedResource.name} </Typography.Title> */}
                            <NewSnippetForm project_id={id} onCancel={() => {
                                searchParams.delete("v")
                                setSearchParams(searchParams)
                            }} onCreate={() => {
                                refetchProjectSnippets()
                            // setReloads(reloads + 1)
                            }} />
                        
                        </Card>
                    )}
                    {selectedResourceId === 'new-workflow' && (
                        <Card
                            title={<>New Workflow</>}
                        >
                            {/* <Typography.Title level={2} style={{marginTop: 0}}>Snippet: {selectedResource.name} </Typography.Title> */}
                            <CreateWorkflowForm
                                project_id={id}
                                onCancel={() => {
                                    searchParams.delete("v")
                                    setSearchParams(searchParams)
                                }}
                                onCreate={() => {
                                    refetchWorkflows()
                                    // setReloads(reloads + 1)
                                }}
                            />

                            {isBetaUser(account?.name) && (
                                <>
                                    <Typography.Title level={3}>Create with AI <BetaTag /></Typography.Title>
                                    <CreateWorkflowFromPromptForm project={project} onCreate={() => refetchAll()} />
                                </>
                            )}
                        </Card>
                    )}
                    {selectedResourceId?.startsWith("clone-workflow-") && (
                        <Card
                            title={<>Clone Workflow</>}
                        >
                            {/* <Typography.Title level={2} style={{marginTop: 0}}>Snippet: {selectedResource.name} </Typography.Title> */}
                            <CreateWorkflowForm
                                project_id={id}
                                showProjectSelection={true}
                                workflowCopySource={(loadedWorkflows || []).filter((r) => r.id === selectedResourceId.replace("clone-workflow-", ""))[0] || null}
                                onCancel={() => {
                                    searchParams.delete("v")
                                    setSearchParams(searchParams)
                                }}
                                onCreate={() => {
                                    refetchWorkflows()
                                    // setReloads(reloads + 1)
                                }}
                            />
                        
                        </Card>
                    )}
                    {selectedResourceId === 'chat' && (
                        <Card>
                            <Typography.Title level={2} style={{marginTop: 0}}>Project: {project.name} </Typography.Title>
                            <Typography.Paragraph>Show {project.name} Chat Here - TODO</Typography.Paragraph>
                        </Card>
                    )}
                    {selectedResource && 'content' in selectedResource && !('is_published' in selectedResource) && (
                        <Card
                            title={<>Snippet: {selectedResource.name} </>}
                            extra={
                                <Space direction='horizontal'>
                                    <Popconfirm title={<Typography.Paragraph>Are you sure you want to remove this Snippet?</Typography.Paragraph>} okText="Delete" cancelText="Cancel" onConfirm={() => {onDeleteProjectSnippet(selectedResource.id)}}>
                                    
                                        <Button icon={<DeleteOutlined />} type='text'>Delete</Button>

                                        {/* <span style={{cursor: 'pointer'}}><DeleteOutlined />&nbsp;&nbsp;Delete</span> */}
                                    </Popconfirm>
                                    <CopyToClipboard
                                        text={selectedResource.content || ''}
                                        tooltip='Copy Snippet to Clipboard'
                                        onCopy={() => ReactGA.event("copy_snippet", {segment_name: selectedResource.name, prompt_id: selectedResource.id, placement: 'project-page-snippet'})}
                                        content={<Button icon={<CopyOutlined />} type='text'>Copy</Button>}
                                        key="list-vertical-copy-o"
                                    />
                                </Space>
                            }
                        >
                            {/* <Typography.Title level={2} style={{marginTop: 0}}>Snippet: {selectedResource.name} </Typography.Title> */}
                            <ManageSnippet snippet={selectedResource} onCancel={() => {}} onUpdate={() => {
                                refetchProjectSnippets()
                                // setReloads(reloads + 1)
                            }} key={selectedResource.id} />
                            
                        </Card>
                    )}
                    {selectedResource && 'content' in selectedResource && ('is_published' in selectedResource) && (
                        <Card
                            title={<>Prompt: {selectedResource.name} </>}
                            extra={
                                <Space direction='horizontal'>

                                    <CopyToClipboard
                                        text={selectedResource.content || ''}
                                        tooltip='Copy Prompt to Clipboard'
                                        onCopy={() => ReactGA.event("copy_prompt", {segment_name: selectedResource.name, prompt_id: selectedResource.id, placement: 'project-page-snippet'})}
                                        content={<Button icon={<CopyOutlined />} type='text'>Copy</Button>}
                                        key="list-vertical-copy-o"
                                    />
                                    {/* <Typography.Text><Link to={`/prompt/${selectedResource.id}`}>Go to Prompt&nbsp;&#8594;</Link></Typography.Text> */}
                                    <Typography.Text><Link to={`/prompt/${selectedResource.id}`}><Button icon={<ArrowRightOutlined />} type='text'>Go to Prompt</Button></Link></Typography.Text>

                                </Space>
                            }
                        >
                            {/* <Typography.Title level={2} style={{marginTop: 0}}>Prompt: {selectedResource.name} </Typography.Title> */}
                            {/* <Typography.Paragraph><Link to={`/prompt/${selectedResource.id}`}>See Prompt</Link></Typography.Paragraph> */}
                            <Typography.Paragraph style={{ fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{selectedResource.content}</Typography.Paragraph>
                        </Card>
                    )}
                    {selectedResource && 'config' in selectedResource && (
                        <Card
                            title={<EditableWorkflowName key={selectedResource.id} workflow={selectedResource} onUpdate={(workflow) => {refetchWorkflows()}} />}
                            extra={
                                <Space direction='horizontal'>
                                    <Popconfirm title={<Typography.Paragraph>Are you sure you want to delete this Workflow?</Typography.Paragraph>} okText="Delete" cancelText="Cancel" onConfirm={() => {onDeleteWorkflow(selectedResource.id)}}>
                                        <Button icon={<DeleteOutlined />} type='text'>Delete</Button>
                                    </Popconfirm>
                                    {/* <Tooltip placement="top" title="Enabled. Click to pause.">
                                            <span style={{cursor: 'pointer'}} onClick={() => {}} ><CopyOutlined />&nbsp;&nbsp;Copy</span>
                                    </Tooltip> */}
                                    {/* <Divider type='vertical' /> */}
                                    <Tooltip placement="top" title="Clone Workflow">
                                        <Button icon={<CopyOutlined />} onClick={() => {
                                            searchParams.set("v", `clone-workflow-${selectedResource.id}`)
                                            setSearchParams(searchParams)
                                        }} type='text'>Clone</Button>
                                    </Tooltip>
                                    {selectedResource.enabled === 1 && (
                                        <Tooltip placement="top" title="Enabled. Click to pause.">
                                            <Button icon={<CheckCircleOutlined style={{ color: enabledColor }} />} onClick={() => onDisableWorkflow(selectedResource.id)} type='text'>Enabled</Button>
                                        </Tooltip>
                                    )}
                                    {selectedResource.enabled === 0 && (
                                        <Tooltip placement="top" title="Disabled. Click to enable.">
                                            <Button icon={<PauseCircleOutlined style={{ color: disabledColor }} />} onClick={() => onEnableWorkflow(selectedResource.id)} type='text'>Disabled</Button>
                                        </Tooltip>
                                    )}
                                    <Typography.Text><Link to={`/workflows/${selectedResource.id}`}><Button icon={<ArrowRightOutlined />} type='text'>Go to Workflow</Button></Link></Typography.Text>
                                </Space>
                            }
                        >
                            {/* <Typography.Title level={2} style={{marginTop: 0}}>Prompt: {selectedResource.name} </Typography.Title> */}
                            {/* <Typography.Paragraph><Link to={`/prompt/${selectedResource.id}`}>See Prompt</Link></Typography.Paragraph> */}
                            {/* <Typography.Paragraph><Link to={`/workflow/${selectedResource.id}`}>See Workflow</Link></Typography.Paragraph> */}
                            {/* <WorkflowDetail workflow={selectedResource} /> */}
                            <>
                                <Typography.Paragraph>Workflow ID: <Typography.Text code>{selectedResourceId}</Typography.Text> <CopyToClipboard text={selectedResourceId || ''} /></Typography.Paragraph>
                                <Tabs
                                    defaultActiveKey={selectedResource.enabled ? 'activity' : 'config'}
                                    // onChange={onChange}
                                    items={[
                                        {
                                            label: `Activity`,
                                            key: 'activity',
                                            children: <>
                                                <ConfiguredWorkflowRunner workflow={selectedResource} key={selectedResourceId} onComplete={() => {refetchWorkflows(); refetchWorkflowExecutions()}} includeHead={true} includeOwnershipLink={false} allowAsDryRun={true} />
                                                {(loadedWorkflowExecutions || []).filter((workflowExecution) => workflowExecution.workflow_id === selectedResourceId).length > 0 && (
                                                    <>
                                                        <Typography.Title level={3}>Executions</Typography.Title>
                                                        <WorkflowExecutionsListUi workflowExecutions={(loadedWorkflowExecutions || []).filter((workflowExecution) => workflowExecution.workflow_id === selectedResourceId)} show_workflow_link={false} />
                                                    </>
                                                )}

                                            </>,
                                        },
                                        {
                                            label: `Configuration`,
                                            key: 'config',
                                            // children: workflowConfiguration(),
                                            children: <>
                                                <WorkflowConfigurationForm workflow={selectedResource} key={selectedResourceId} workflowPrompts={loadedPrompts || []} projectSnippets={loadedProjectSnippets || []} onSave={() => {
                                                    refetchWorkflows()
                                                    // setReloads(reloads + 1)
                                                }} />
                                            </>,
                                        },
                                    ]}
                                />
                            </>
                        </Card>

                    )}

                    
                </Col>

            </Row>


        </>
    )



    return (
        <div className="App">
            <div className="App-content app-content-wide">
                <div className="App-page-content">
                    {content}
                </div>
            </div>
        </div>
    );
}
