import { ArrowRightOutlined, DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Link } from "react-router-dom";
import { Account } from '../types/types';

import React from "react";
import ReactDOM from "react-dom/client";
import { FileOutlined, InboxOutlined } from '@ant-design/icons';
import type { GetProp, UploadProps } from 'antd';
import { Alert, Button, Modal, Space, Divider, Typography, Upload, message } from 'antd';
import { useState } from 'react';
import { selectAccount } from '../features/login/loginSlice';
import { uploadSignedFileToS3 } from '../services/s3';
import { assetsGetWriteUrl, assetsUploadAudioFile } from '../services/signalApi';

import type { RcFile } from 'antd/es/upload';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { AudioRecorder as PPAudioRecorder } from './AudioRecorder'
import { useAppSelector } from '../app/hooks';
const { Dragger } = Upload;


interface AudioFileUploadCompletion {
    transcription: string
    audio_file_id: string
}

interface AudioFileUploadParams {
    transcribe?: boolean
    onComplete: (upload_info: AudioFileUploadCompletion) => void
}


interface UploadAudioFileModalParams {
    isOpen: boolean
    transcribe?: boolean
    onCancel: () => void
    onCreate: (upload_info: AudioFileUploadCompletion) => void
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

// const getBase64v2 = (img: FileType, callback: (url: string) => void) => {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result as string));
//     reader.readAsDataURL(img);
// };


export function UploadAudioFileFileModal({
    isOpen,
    transcribe=false,
    onCancel,
    onCreate,
}: UploadAudioFileModalParams) {

    const account = useAppSelector(selectAccount);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedFileSuccess, setUploadedFileSuccess] = useState(false);
    const [assetUrl, setAssetUrl] = useState<string | null>(null);

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);



    const handleCancel = () => {
        setIsUploading(false)
        setUploadedFileSuccess(false)
        setAssetUrl(null)
        onCancel()
    }

    const deleteFile = () => {
        setUploadedFile(null)
    }

    const uploadFile = () => {
        console.log("uploading")
        if (uploadedFile && assetUrl && account) {
            setIsUploading(true)
            uploadSignedFileToS3(assetUrl, uploadedFile, uploadedFile.type).then((res) => {
                console.log("uploadSignedFileToS3", {res})
                if (res.status === 200) {
                    // onCreate({transcription: "foobaz", "audio_file_id": "nicee"})
                    return assetsUploadAudioFile(account.id, assetUrl, transcribe).then((res) => {
                    // return assetsUpdateDataSetSource(account.id, dataset.id, assetUrl).then((res) => {
                        console.log("assetsUploadAudioFile", {res})
                        if (res.status === 200) {
                            const data = res.data
                            onCreate({transcription: data.transcription, audio_file_id: data.audio_file_id})
                            setUploadedFileSuccess(true)
                        } else {
                            message.error("An error occurred when uploading your audio file.")
                        }
                        setUploadedFile(null)
                    }).catch((err) => {
                        console.log("err", {err})
                        message.error("An error occurred when uploading your audio file.")
                    }).finally(() => {
                        setIsUploading(false)
                    })
                } else {
                    message.error("An error occurred when uploading your audio file.")
                }
            }).finally(() => {
                setIsUploading(false)
            })
        }
    }
    // File uploads are currently limited to 25 MB and the following input file types are supported: mp3, mp4, mpeg, mpga, m4a, wav, and webm. 
    const acceptableFileTypes = ['audio/mp3', 'audio/mp4', 'audio/mpeg', 'audio/mpga', 'audio/m4a', 'audio/wav', 'audio/webm', 'audio/x-m4a']
    const isFileAudio = (file: File) => acceptableFileTypes.includes(file.type)

    const handleFileUpload = (file: File) => {

        console.log("handleFileUpload", {file})
        // do some checks here
        let hasValidType = false;
        const isAudio = isFileAudio(file)
        if (isAudio) {
            hasValidType = true;
        }

        if (!hasValidType) {
            message.error(`You can only upload a Audio File (detected ${file.type}).`);
        }
        if (hasValidType && assetUrl === null && account) {
            assetsGetWriteUrl(account.id, file.name, file.type).then((res: any) => {
                console.log("assetsGetWriteUrl", {res})
                if (res.status === 200) {
                    const url = res.data.url
                    if (url) {
                        setAssetUrl(url)
                    }
                }
            })
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB.');
        }

        if (hasValidType) {
            setUploadedFile(file)
        }

    }
    const props: UploadProps = {
        name: 'file',
        multiple: false,
        action: (file: FileType) => "false",
        // action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        beforeUpload(file: FileType) {
            console.log("beforeUpload", {file})
            handleFileUpload(file)
            return false;
        },
        onChange(info) {
            console.log("onChange", {info})

            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log("done")
            // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                console.log("error")

            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    const uploadFileFileType = uploadedFile && isFileAudio(uploadedFile) ? 'audio' : null

    const recordFormBody = (
        <>


            {(isUploading && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}
            <Typography.Paragraph>
                Upload an Audio File or Record. {transcribe ? 'We will transcribe it for you.' : ''}
            </Typography.Paragraph>

            {uploadedFileSuccess && (
                <div>
                    <Alert type='success' message='File uploaded successfully!' />
                    <Space style={{justifyContent: "space-between", display: "flex", marginTop: '12px'}}>
                        <span></span>
                        <Space direction='horizontal'>
                            <Button type='primary' onClick={handleCancel}>Okay!</Button>
                        </Space>
                    </Space>

                </div>
            )}

            {uploadedFile && (
                <div>
                    <p>File Staged!</p>
                    <Alert type='info' message={
                        <div>
                            <p><FileOutlined />  {uploadedFile.name} ({uploadFileFileType})</p>
                            <audio controls src={URL.createObjectURL(uploadedFile)} style={{ width: '100%', marginTop: '8px' }}>
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    } />
                    <Space style={{justifyContent: "space-between", display: "flex", marginTop: '12px'}}>
                        <span></span>
                        <Space direction='horizontal'>
                            <Button disabled={isUploading} onClick={deleteFile}>Delete</Button>
                            <Button type='primary' disabled={isUploading} loading={isUploading} onClick={uploadFile}>Upload</Button>
                        </Space>
                    </Space>

                </div>
            )}
            {!uploadedFileSuccess && !uploadedFile && (
                <>
                <PPAudioRecorder style={{marginBottom: '8px'}} onComplete={(blob: Blob) => {
                    const url = URL.createObjectURL(blob);
                    // like audio/ogg; codecs=opus
                    const clean_type = blob.type.split(';')[0]
                    const extension = acceptableFileTypes.includes(blob.type) ? clean_type.split('/')[1] : 'webm'
                    const filename = `recording.${extension}`
                    message.error(`fake error. type is '${blob.type}', filename is '${filename}'`)
                    // const fileObj = new File([blob], filename, {type: blob.type})
                    const fileObj = new File([blob], filename, {type: acceptableFileTypes.includes(blob.type) ? blob.type : "audio/webm"})
                    // fileObj.lastModifiedDate = new Date()
                    // fileObj.uid = filename
                    // fileObj.
                    handleFileUpload(fileObj)
                    console.log("completionBlob", {blob})
                }} />
                    <Divider>or upload</Divider>
            </>
            )}
            {!uploadedFileSuccess && !uploadedFile && (
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag a file to this area to upload</p>
                    <p className="ant-upload-hint">
                    Upload an Audio File, probably with some spoken words, generally for transcription.
                    </p>
                </Dragger>
            )}
            





        </>
    )

    return (
        <Modal title="Upload Audio" open={isOpen} footer={null} onCancel={handleCancel}>
            {recordFormBody}
        </Modal>
    )


}

export function AudioFileUpload({
    transcribe=false,
    onComplete
}: AudioFileUploadParams) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <>
        <Button type="default" htmlType="submit" disabled={false} onClick={() => setIsModalOpen(true)}>
            Use Audio
        </Button>
        <UploadAudioFileFileModal isOpen={isModalOpen} transcribe={transcribe} onCreate={(uploadInfo) => {onComplete(uploadInfo); setIsModalOpen(false);}} onCancel={() => setIsModalOpen(false)} />
        </>
    ) 
    
}
