
import type { SelectProps } from 'antd';
import { Alert, Button, Form, Input, Modal, Select, Typography, message, Radio } from 'antd';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { selectAccount } from '../features/login/loginSlice';
import { loadProjectsAsync, selectProjects, selectStatus as selectProjectsStatus } from '../features/projects/projectsSlice';
import { createWorkflowsFromPrompt } from '../services/signalApi';
import { extractBracketContents, extractSingleLineBracketContents, replaceBracketContents, replaceBracketContentsWithNode } from '../utils'
import { Project as ProjectType } from '../types/types';
import { useAppSelector, useAppDispatch } from '../app/hooks';



interface WorkflowFormSubmission {
    prompt: string
}


interface WorkflowModalFromPromptFormParams {
    project: ProjectType
    onCreate: () => void
}


export function CreateWorkflowFromPromptForm({
    project,
    onCreate,
}: WorkflowModalFromPromptFormParams) {
    const [form] = Form.useForm();
    // const workflowName = Form.useWatch('name', form);

    const account = useAppSelector(selectAccount);
    
    const [isCreating, setIsCreating] = useState(false);
    const [createdWorkflowIds, setCreatedWorkflowIds] = useState<string[] | null>(null);
    const [response, setResponse] = useState<string | null>(null);

    const onSubmit = (values: WorkflowFormSubmission) => {
        if (account) {
            console.log("onCreateWorkflow values", {values})
            setIsCreating(true)
            createWorkflowsFromPrompt(account.id, project.id, values.prompt).then((res) => {
                    console.log("created", {res})
                    if (res.status === 200) {
                        const response_data = res.data
                        // check response_data type to see if it's a dict, typesafe
                        if (typeof response_data === "object"){
                            setCreatedWorkflowIds(response_data.workflow_ids)
                            setResponse(response_data.response)
                            onCreate()
                        }
                        setIsCreating(false)
                    } else {
                        console.log("err", {res})
                        // message.error(res.data)
                        setIsCreating(false)
                    }
                }
            )
        }
    }

    const workflowFormBody = (
        <Form
        name="basic"
        form={form}
        layout='vertical'
        initialValues={{name: prompt.name, workflow_type: "timed"}}
        onFinish={onSubmit}
        // onFinishFailed={onCreateWorkflowFailed}
        autoComplete="off"
        requiredMark={"optional"}
        >
            {(isCreating && (
                <Typography.Paragraph>Loading...</Typography.Paragraph>
            ))}

            {response && createdWorkflowIds && (
                <Alert
                    style={{marginBottom: '8px'}}
                    message="Created Workflows"
                    description={response}
                    type="success"
                    showIcon
                    closable
                    onClose={() => {setResponse(null); setCreatedWorkflowIds(null);}}
                />
            )}
            {response && createdWorkflowIds != null && createdWorkflowIds.length === 0 && (
                <Alert
                    style={{marginBottom: '8px'}}
                    message="Complete"
                    description={response}
                    type="info"
                    showIcon
                    closable
                    onClose={() => {setResponse(null); setCreatedWorkflowIds(null);}}
                />

            )}


                <>

                    <Form.Item
                        label="Explain what you're trying to do."
                        name="prompt"
                        rules={[{ required: true, message: 'Need to Explain' }]}
                    >
                        <Input.TextArea placeholder='send me a weekly email about good seasonal tourism options near salt lake city' />
                    </Form.Item>

                </>

                    <Form.Item
                        wrapperCol={{ offset: 0, span: 24 }}
                    >
                        <Button type="primary" htmlType="submit" style={{float: 'right', width: 'unset'}} disabled={
                            isCreating
                        }>
                            Go
                        </Button>
                    </Form.Item>

        </Form>
    )

    return workflowFormBody

    
}
